import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import { fetchVacanciesQuery } from "../../../graphql/queries/fetchVacancies"
import BackButton from "../../BackButton/BackButton"
import Button from "../../Button"
import Notification from "../../Notification"
import SectionHeader from "../../SectionHeader"
import VacancyItem from "../VacancyItem"
import * as styles from "./OpenVacanciesPage.module.scss"

export const OpenVacanciesPage: React.FC = () => {
  const {
    allContentfulVacancy: { nodes },
  } = fetchVacanciesQuery()

  return (
    <div className={styles.main}>
      <section>
        <div className={styles.container}>
          <div className={styles.headers}>
            <div className={styles.headersSectionHeader}>
              <SectionHeader
                main="Open Vacancies"
                secondary="We are looking for"
              />
            </div>
            <BackButton />
          </div>
          <h4>
            Work from anywhere - Registration assistance to Georgia/Spain
            (optional)
          </h4>
          <div className={styles.vacancies}>
            {nodes.map(node => (
              <VacancyItem
                key={node.slug}
                title={node.title}
                slug={node.slug}
                description={node.description.childMarkdownRemark.html}
                chips={node.chips}
              />
            ))}
          </div>
          <Button
            text="Join Us"
            color="violet"
            onClick={() => (window.location.href = "mailto:jobs@v4scale.com")}
            className={styles.btn}
          />
          <Notification
            className={styles.notification}
            link={
              <AnchorLink to="/#join-us">
                <h5 className={styles.link}>Why join our team?</h5>
              </AnchorLink>
            }
          />
        </div>
      </section>
    </div>
  )
}

import { graphql, useStaticQuery } from "gatsby";

interface Vacancy {
  title: string,
  slug: string,
  description: { childMarkdownRemark: {html: string} },
  chips: string[],
  jobTags: string[],
}

interface VacanciesQueryData {
  allContentfulVacancy: {
    nodes: Vacancy[]
  }
}

export const fetchVacanciesQuery = (): VacanciesQueryData => {
  const data = useStaticQuery(graphql`
  query VacanciesQuery {
    allContentfulVacancy {
      nodes {
        title
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
        chips
        jobTags
      }
    }
  }`)
  return data
}
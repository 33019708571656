import React from "react"
import Layout from "../../components/Layout/Layout"
import OpenVacanciesPage from "../../components/OpenVacancies/OpenVacanciesPage"
import SEO from "../../components/SEO"

const OpenVacancies: React.FC = () => (
  <Layout>
    <div>
      <SEO
        title="V4Scale vacancies"
        description="V4Scale is an Israeli company that expands the local R&D capabilities of leading Israeli and US technology companies with their diverse and skilled remote workforce from all over the world."
      />
      <OpenVacanciesPage />
    </div>
  </Layout>
)
export default OpenVacancies

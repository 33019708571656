import { Link } from "gatsby"
import React from "react"
import VacancyChip from "../VacancyChip"
import * as styles from "./VacancyItem.module.scss"

export interface VacancyItemProps {
  title: string
  slug: string
  description: string,
  chips: string[],
}

export const VacancyItem: React.FC<VacancyItemProps> = ({ title, slug, description, chips }) => {
  return (
    <div className={styles.vacancy}>
      <div className={styles.chips}>
        {chips.map(chip => (
          <VacancyChip key={slug + chip} chip={chip} />
        ))}
      </div>
      <Link
        to={`/vacancies/${slug}`}
        className={styles.link}
      >
        <div className={styles.heading}>{title}</div>
      </Link>
      <div
        className={styles.desc}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}
